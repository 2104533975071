import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Menu } from "./components/menu/menu"
import { Data } from "./pages/data/data"
import { Home } from "./pages/home/home"
import { Game } from "./pages/game/game"
import { Intership } from "./pages/intership/intership"
import { LoyalityProgram } from "./pages/loyality-program/loyality-program"

function App() {
  return (
    <BrowserRouter>
        <Menu />
        <Routes className='container'>
          <Route path="/" element={<Home />} />
          <Route path="/data" element={<Data />} />
          <Route path="/game" element={<Game />} />
          <Route path="/loyality-program" element={<LoyalityProgram />} />
          {/*<Route path="/intership" element={<Intership />} />*/}
        </Routes>
    </BrowserRouter>
  );
}

export default App;
